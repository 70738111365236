import React, {useContext} from 'react';
import { Button, Row } from 'antd';

import Icon from '../icon/Icon';
import UserContext from '../../context/UserContext';
import { callApi } from '../../helpers/Api';
// import EmailInput from '../forms/EmailInput';

const SocialAuthButtons = (props) => {
    const {loggedInUser, setLoggedInUser} = useContext(UserContext);

    // const [useEmail, setUseEmail] = useState(false);

    let preText, googlePreText, facebookPreText, applePreText, authUrlGoogle, authUrlFacebook, authUrlApple;
    preText = googlePreText = facebookPreText = applePreText = 'Sign in';
    authUrlGoogle = authUrlFacebook = authUrlApple = 'login';
    if(props.mode === 'register') {
        preText = googlePreText = facebookPreText = applePreText = 'Register';
    } else if(props.mode === 'link') {
        preText = googlePreText =  facebookPreText = applePreText = 'Link';
        console.log(loggedInUser.apple_api_id)
        if(loggedInUser) {
            if(loggedInUser.google_api_id) {
                googlePreText = 'Unlink';
                authUrlGoogle = 'unlink'
            }
            if(loggedInUser.facebook_api_id) {
                facebookPreText = 'Unlink';
                authUrlFacebook = 'unlink'
            }
            if(loggedInUser.apple_api_id) {
                console.log('gothere')
                applePreText = 'Unlink';
                authUrlApple = 'unlink'
            }
        }
    }

    return (
        // !useEmail ? (
            <div>
            <Row>
                By clicking one of the "{preText}" buttons below you agree to our terms and agreement.
            </Row>
            
            <Row style={{margin: '5px'}}>
                <Button 
                    block
                    href={authUrlGoogle === 'unlink' ? null : "/auth/" + authUrlGoogle + "/google"}
                    onClick={() => {
                        if(authUrlGoogle === 'unlink') {
                            callApi('/auth/' + authUrlGoogle + '/google')
                            .then(res =>{
                                if(res.user) {
                                    setLoggedInUser(res.user);
                                }
                            })
                        }
                    }}
                    style={{
                        color: "#FFF",
                        backgroundColor: "#de5246",
                        borderColor: "transparent",
                    }}
                >
                    <Icon name={['fab', 'google']} />
                    &nbsp;{googlePreText + ' with Google'}
                </Button>
            </Row>
            <Row style={{margin: '5px'}}>
                <Button 
                    block
                    href={authUrlFacebook === 'unlink' ? null : "/auth/" + authUrlFacebook + "/facebook"}
                    onClick={() => {
                        if(authUrlFacebook === 'unlink') {
                            callApi('/auth/' + authUrlFacebook + '/facebook')
                            .then(res =>{
                                if(res.user) {
                                    setLoggedInUser(res.user);
                                }
                            })
                        }
                    }}
                    style={{
                        color: "#FFF",
                        backgroundColor: "#395697",
                        borderColor: "transparent",
                    }}
                >
                    <Icon name={['fab', 'facebook']} />
                    &nbsp;{facebookPreText + ' with facebook'}
                </Button>
            </Row>
            <Row style={{margin: '5px'}}>
                <Button 
                    block
                    className="apple-signin"
                    href={authUrlApple === 'unlink' ? null : "/auth/" + authUrlApple + "/apple"}
                    onClick={() => {
                        if(authUrlApple === 'unlink') {
                            callApi('/auth/' + authUrlApple + '/apple')
                            .then(res =>{
                                if(res.user) {
                                    setLoggedInUser(res.user);
                                }
                            })
                        }
                    }}
                >
                    <Icon name={['fab', 'apple']} />
                    &nbsp;{applePreText + ' with apple'}
                </Button>
            </Row>
            {/* <Row>
                <Button 
                    block
                    style={{
                        color: "#FFF",
                        backgroundColor: "#535a60",
                        borderColor: "transparent",
                    }}
                    onClick={() => {setUseEmail(true)}}
                >
                <Icon name={'envelope'} />
                &nbsp;{preText + 'email'}
                </Button>
            </Row>
            <br /> */}
        </div>
        /* removing magic link
        ) : (
            <div>
                <EmailInput 
                    buttonText={'Send Magic-Link!'}
                    handleSubmit={(email) => {
                        return callApi('/auth/login/magiclink', {
                            method: 'POST',
                            body: JSON.stringify({
                                email
                            })
                        })
                    }}
                />
                <a onClick={() => {setUseEmail(false)}}>
                <Icon name={'angle-double-left'} />
                &nbsp;{'more sign in options'}
                </a>
            </div>
        )
        */
    );
}

export default SocialAuthButtons
