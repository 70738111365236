import React, { useState, useContext, useEffect } from 'react'
import Link from 'next/link'
import {useRouter} from 'next/router'
import UserContext from '../../context/UserContext'
import { Form, Input, Button, Divider, message } from 'antd';

import CenteredItem from '../common/CenteredItem';
import SocialAuthButtons from './SocialAuthButtons';

const LoginForm = (props) => {
    const [from, setFrom] = useState('/');
    const [message, setMessage] = useState('');
    const {handleLogin} = useContext(UserContext);
    const router = useRouter();

    useEffect(() => {
        const welcomeMessage = [
            "Welcome back friend!",
            "You look wonderful today!",
            "We have been missing you.",
            "Welcome all!",
            "Greetings earthling.",
            "Have you read them all?",
            "Back so soon?",
            "It's been a while!",
            "Ahoy matey.",
            "Do you comma here often?",
            "Metaphors be with you.",
            "Practice safe text: use commas.",
            "Bad spelling makes me [sic].",
            "Past, present, and future walked into a bar. It was tense.",
            "Broken pencils are pointless.",
            "Your day is now fully booked.",
            "Talk wordy to me.",
            "Reading is a novel idea.",
            "Bookmark it!",
            "You could go another round",
            "You can't start a new chapter if you keep rereading the last one.",
            "I want that fable on my desk, AESOP!",
        ];
        setMessage(welcomeMessage[Math.floor(Math.random() * welcomeMessage.length)]);
    }, [])

    useEffect(() => {
        setTimeout(()=> {
            if(router?.query?.from)
                setFrom(decodeURIComponent(router.query.from))
            if(router?.query?.message && router.query.message === 'denied')
                setMessage(<span style={{color: "red"}}>uhoh... you need to be logged in to access this!</span>)
            if(router?.query?.message && router.query.message === 'already-registered') {
                setMessage(<span style={{color: "red"}}>You already registered with this email via another method. Please log in using that method then link it in settings.</span>)
            }
        }, 0)
    }, [router.query])

    const onFinish = values => {
        handleLogin(values.username, values.password, from)
    };

    const inputStyle = {marginBottom: '0'}
    return (
        <CenteredItem
            header={'Login'}
            description={message}
            item={
                <div>
                    <SocialAuthButtons mode='login' />
                    <Divider className="text-darkable">or</Divider>
                    <Form 
                        name="login"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item 
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]} 
                            style={inputStyle} 
                        >
                            <Input 
                                data-cy="username"
                                placeholder="Username" 
                            />
                        </Form.Item>
                        <Form.Item 
                            name="password" 
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            style={inputStyle} 
                        >
                            <Input.Password
                                data-cy="password"
                                placeholder="Password"
                            />
                        </Form.Item>
                        <a className="login-form-forgot" href="/forgot/password">Forgot password?</a>
                        &nbsp;
                        <a className="login-form-forgot" href="/forgot/username">Forgot username?</a>
                        <Form.Item style={inputStyle} >
                            <Button block type="primary" htmlType="submit">Login</Button>
                        </Form.Item>
                        <Link href={{
                            pathname: '/register',
                            query: {from: encodeURIComponent(from)}
                        }}>
                            <a>Don't have an account? Click here to register.</a>
                        </Link>
                    </Form>
                </div>
            }
        />
    )
}
export default LoginForm;