import CustomLayout from '../components/CustomLayout';
import LoginForm from '../components/auth/LoginForm'

const LoginPage = (props) => {
  return (
        <CustomLayout 
            title="login - fablement"
            showFooter={false}
        >
            <LoginForm />
        </CustomLayout>
  );
}

export default LoginPage;